









































































import { Component, Model } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { RoleEntityModel, RoleQueryModel } from '@common-src/entity-model/role-entity';
import RoleService from '@common-src/service/role-v2';
import RoleDetailComponent from '@common-src/pages/admin-dashboard/corp-detail-drawer.vue';
import RoleTopoComponent from './role-topo-drawer.vue';
import { UserStoreModule } from '@common-src/store/modules/user';
import LinkUserDialog from './link-user-dialog.vue';
import LinkDepartmentDialog from './link-department-dialog.vue';
import RoleUserDrawerComponent from './role-user-drawer.vue';

@Component({
    components: {
        'role-detail-drawer': RoleDetailComponent,
        'link-user-dialog': LinkUserDialog,
        'role-topo-drawer': RoleTopoComponent,
        'link-department-dialog': LinkDepartmentDialog,
        'role-user-drawer': RoleUserDrawerComponent
    }
})
export default class RoleListComponent extends TableDialogFormComponent<RoleEntityModel, RoleQueryModel> {
    RoleEntityModel = RoleEntityModel;
    created() {
        this.initTable({
            service: RoleService,
            queryModel: new RoleQueryModel(),
            tableColumns: RoleEntityModel.getTableColumns()
        });
        this.getList();
    }
    configClick(model: RoleEntityModel) {
        (this.$refs.roleDetailDrawer as RoleDetailComponent).drawerRoleOpen(model.id, UserStoreModule.CurrentUserPermissionType, model.authority);
        (this.$refs.roleDetailDrawer as RoleDetailComponent).drawerTitle = model.name;
    }
    linkUserClick(model: RoleEntityModel) {
        (this.$refs.linkUserDialog as LinkUserDialog).dialogOpen(model.id);
    }
    linkDepartmentClick(model: RoleEntityModel) {
        (this.$refs.linkDepartmentDialog as LinkDepartmentDialog).dialogOpen(model.id);
    }

    roleUserClick(model: RoleEntityModel) {
        (this.$refs.roleUserDrawerComponent as RoleUserDrawerComponent).drawerRoleOpen(model.id);
    }

    topoConfigClick(model: RoleEntityModel) {
        (this.$refs.roleTopoDrawer as RoleTopoComponent).drawerRoleOpen(model.id);
        (this.$refs.roleTopoDrawer as RoleTopoComponent).drawerTitle = `${model.name}-组态权限配置`;
    }
    formDialogOK() {
        this.getList();
    }

    get IsProject() {
        return !!UserStoreModule.CurrentProject;
    }

    formatScopes(val: any) {
        if (_.isEmpty(val)) {
            return '';
        }
        return val.length > 3 ? _.join(val.slice(0, 3), ', ') + ` + ${val.length - 3}` : _.join(val, ', ');
    }

    copyClick(record: RoleEntityModel) {
        RoleService.copyRole(record.id).then(res => {
            this.showMessageSuccess('复制成功');
            this.getList();
        });
    }

    batchDeleteClick() {
        if (_.isEmpty(this.selectedRowKeys)) {
            this.showMessageWarning('请选择要删除的角色');
            return;
        }
        const confirm = this.$confirm({
            title: '确认删除',
            content: `确认批量删除角色?`,
            onOk: () => {
                RoleService.batchDeleteRole(this.selectedRowKeys).then(res => {
                    this.showMessageSuccess('删除成功');
                    this.getList();
                });
            },
            onCancel: () => {
                confirm.destroy();
            }
        });
    }
}

