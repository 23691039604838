var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: "角色人员",
        placement: _vm.placement,
        width: "50vw",
        closable: _vm.closable,
        visible: _vm.drawerVisible,
        "destroy-on-close": true,
      },
      on: { close: _vm.drawerClose },
    },
    [
      _c(
        "div",
        { staticClass: "corp-detail-drawer-component" },
        [
          _c(
            "a-tabs",
            {
              attrs: { "active-key": _vm.type },
              on: { change: _vm.tabChangeHandler },
            },
            [
              _vm._l(_vm.tabsOptions, function (item) {
                return _c("a-tab-pane", {
                  key: item.key,
                  attrs: { tab: item.name },
                })
              }),
              _c(
                "template",
                { slot: "tabBarExtraContent" },
                [
                  _c(
                    "jtl-button",
                    {
                      staticClass: "save-button m-r-12",
                      on: { click: _vm.batchDelete },
                    },
                    [_vm._v("移除")]
                  ),
                  _c(
                    "jtl-button",
                    {
                      staticClass: "save-button",
                      attrs: { type: "primary" },
                      on: { click: _vm.addClickHandler },
                    },
                    [_vm._v("+ 添加")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("a-table", {
            attrs: {
              loading: _vm.listLoading,
              columns: _vm.tableColumns,
              "data-source": _vm.listData,
              pagination: false,
              scroll: _vm.tableScroll,
              "row-key": "id",
              "row-selection": {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectChange,
              },
              size: _vm.TableSize,
            },
            scopedSlots: _vm._u([
              {
                key: "action",
                fn: function (text, record) {
                  return [
                    _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title: "确认移除?",
                          "ok-text": "确认",
                          placement: "left",
                          "cancel-text": "取消",
                        },
                        on: {
                          confirm: function ($event) {
                            return _vm.batchDeleteClick([record.id])
                          },
                        },
                      },
                      [
                        _c("a", { staticClass: "jtl-del-link" }, [
                          _vm._v("移除"),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("link-userD-department-dialog", {
        ref: "linkUserDepartmentDialog",
        on: { dialogOK: _vm.init },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }