








































import { Component, Watch, Mixins } from 'vue-property-decorator';
import DrawerComponent from '@common-src/mixins/drawer-component';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import RoleService from '@common-src/service/role-v2';
import { UserStoreModule } from '@common-src/store/modules/user';
import UserService from '@common-src/service/user-v2';
import LinkUserDepartmentDialog from './link-user-department-dialog.vue';

@Component({
    components: {
        'link-userD-department-dialog': LinkUserDepartmentDialog
    }
})
export default class RoleUserDrawerComponent extends Mixins(DrawerComponent, TableDialogFormComponent) {
    roleId: string = undefined;
    type: string = 'USER';
    userService: UserService = new UserService();
    tabsOptions: any = [
        { key: 'USER', name: '成员列表' },
        { key: 'DEPARTMENT', name: '部门列表' }
    ];
    userListData: any = [];
    departmentListData: any = [];
    checkedUsers: any = [];
    checkedDepartments: any = [];

    drawerRoleOpen(id: string) {
        this.roleId = id;
        this.type = 'USER';
        this.drawerVisible = true;
        this.initTableColumns(this.type);
        this.init();
    }

    init() {
        this.selectedRowKeys = [];
        this.listLoading = true;
        if (this.type === 'USER') {
            RoleService.getUsersByRoleId(this.roleId).then(res => {
                this.checkedUsers = res;
                this.listData = this.checkedUsers;
            }).finally(() => {
                this.listLoading = false;
            });
        } else {
            this.userService.getLinkDepartement(this.roleId).then(res => {
                this.checkedDepartments = res;
                this.listData = this.checkedDepartments;
            }).finally(() => {
                this.listLoading = false;
            });
        }
    }

    tabChangeHandler(key: string) {
        this.type = key;
        this.initTableColumns(key);
        this.init();
        this.selectedRowKeys = [];
    }

    initTableColumns(type: string) {
        switch (type) {
            case 'USER':
                this.tableColumns = [
                    { title: '姓名', dataIndex: 'name' },
                    { title: '联系手机号', dataIndex: 'phone' },
                    { title: '所在部门', dataIndex: 'orgName' }
                ];
                break;
            case 'DEPARTMENT':
                this.tableColumns = [
                    { title: '部门', dataIndex: 'name' },
                    { title: '所属', dataIndex: 'parentName' }
                ];
                break;
        }
        this.tableColumns.push(
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        );
    }

    batchDeleteClick(ids: any) {
        if (this.type === 'USER') {
            return RoleService.batchDeleteRoleUsers({ roleId: this.roleId, users: ids }).then((ret) => {
                this.showMessageSuccess('移除成功');
                this.init();
            });
        } else {
            return RoleService.batchDeleteRoleOrgs({ roleId: this.roleId, orgIds: ids }).then((ret) => {
                this.showMessageSuccess('移除成功');
                this.init();
            });
        }
    }

    batchDelete() {
        if (this.selectedRowKeys.length === 0) {
            this.showMessageWarning(`请选择要批量移除的${this.type === 'USER' ? '成员' : '部门'}`);
            return;
        }
        this.$confirm({
            title: `批量移除`,
            content: `请确认是否批量移除${this.type === 'USER' ? '成员' : '部门'}`,
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                this.batchDeleteClick(this.selectedRowKeys);
            }
        });
    }

    addClickHandler() {
        if (this.type === 'USER') {
            this.userService.getLinkDepartement(this.roleId).then(res => {
                const linkIds = [..._.map(this.listData, (r: any) => r.id), ..._.map(res, (r: any) => r.id)];
                (this.$refs.linkUserDepartmentDialog as LinkUserDepartmentDialog).dialogOpen(this.roleId, linkIds, 'user');
            });
        } else {
            RoleService.getUsersByRoleId(this.roleId).then(res => {
                const linkIds = [..._.map(this.listData, (r: any) => r.id), ..._.map(res, (r: any) => r.id)];
                (this.$refs.linkUserDepartmentDialog as LinkUserDepartmentDialog).dialogOpen(this.roleId, linkIds, 'org');
            });
        }
    }
}
