var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "role-list-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("角色列表")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-card",
            { attrs: { title: "角色列表" } },
            [
              _c(
                "div",
                { staticClass: "flex m-b-20 justify-between" },
                [
                  _c("a-input-search", {
                    staticStyle: { width: "400px" },
                    attrs: { placeholder: "请输入角色名称", "allow-clear": "" },
                    on: { search: _vm.searchClick },
                    model: {
                      value: _vm.queryModel.params,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryModel, "params", $$v)
                      },
                      expression: "queryModel.params",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c(
                        "jtl-button",
                        {
                          staticClass: "m-r-16",
                          on: { click: _vm.batchDeleteClick },
                        },
                        [_vm._v("移除角色")]
                      ),
                      _c(
                        "jtl-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.addClick(new _vm.RoleEntityModel())
                            },
                          },
                        },
                        [_vm._v("+ 添加角色")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("a-table", {
                attrs: {
                  loading: _vm.listLoading,
                  columns: _vm.tableColumns,
                  "data-source": _vm.listData,
                  pagination: _vm.Pagination,
                  scroll: _vm.tableScroll,
                  "row-key": "id",
                  size: _vm.TableSize,
                  "row-selection": {
                    selectedRowKeys: _vm.selectedRowKeys,
                    onChange: _vm.onSelectChange,
                  },
                },
                on: { change: _vm.tableChange },
                scopedSlots: _vm._u([
                  {
                    key: "scopes",
                    fn: function (text, record) {
                      return [
                        _c(
                          "a",
                          {
                            staticClass: "jtl-link",
                            on: {
                              click: function ($event) {
                                return _vm.configClick(record)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.formatScopes(text)))]
                        ),
                      ]
                    },
                  },
                  {
                    key: "updateUser",
                    fn: function (text) {
                      return [
                        text
                          ? _c("a-tag", { attrs: { color: "blue" } }, [
                              _vm._v(_vm._s(text)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "action",
                    fn: function (text, record) {
                      return [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.configClick(record)
                              },
                            },
                          },
                          [_vm._v("权限配置")]
                        ),
                        _vm.IsProject
                          ? _c("a-divider", { attrs: { type: "vertical" } })
                          : _vm._e(),
                        _vm.IsProject
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.topoConfigClick(record)
                                  },
                                },
                              },
                              [_vm._v("组态配置")]
                            )
                          : _vm._e(),
                        record.authority === "USER"
                          ? _c(
                              "span",
                              [
                                _c("a-divider", {
                                  attrs: { type: "vertical" },
                                }),
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.roleUserClick(record)
                                      },
                                    },
                                  },
                                  [_vm._v("角色人员")]
                                ),
                                _c("a-divider", {
                                  attrs: { type: "vertical" },
                                }),
                                _c(
                                  "a-popconfirm",
                                  {
                                    attrs: {
                                      title: "确认复制?",
                                      "ok-text": "确认",
                                      "cancel-text": "取消",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.copyClick(record)
                                      },
                                    },
                                  },
                                  [
                                    _c("a", { staticClass: "jtl-link" }, [
                                      _vm._v("复制"),
                                    ]),
                                  ]
                                ),
                                _c("a-divider", {
                                  attrs: { type: "vertical" },
                                }),
                                _c(
                                  "a",
                                  {
                                    staticClass: "jtl-edit-link",
                                    on: {
                                      click: function ($event) {
                                        return _vm.editClick(record)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _c("a-divider", {
                                  attrs: { type: "vertical" },
                                }),
                                _c(
                                  "a-popconfirm",
                                  {
                                    attrs: {
                                      title: "确认删除?",
                                      "ok-text": "确认",
                                      "cancel-text": "取消",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.deleteClick(record)
                                      },
                                    },
                                  },
                                  [
                                    _c("a", { staticClass: "jtl-del-link" }, [
                                      _vm._v("删除"),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.formDialogOK },
      }),
      _c("role-detail-drawer", {
        ref: "roleDetailDrawer",
        on: { dialogOK: _vm.getList },
      }),
      _c("role-topo-drawer", { ref: "roleTopoDrawer" }),
      _c("link-user-dialog", {
        ref: "linkUserDialog",
        on: { dialogOK: _vm.formDialogOK },
      }),
      _c("link-department-dialog", {
        ref: "linkDepartmentDialog",
        on: { dialogOK: _vm.formDialogOK },
      }),
      _c("role-user-drawer", { ref: "roleUserDrawerComponent" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }